import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode
  className?: string
}

const easing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
};

const FadeInUp = ({ children, className = '', ...rest }: Props) => (
  <motion.div variants={fadeInUp} className={className} {...rest}>
    {children}
  </motion.div>
);

export default FadeInUp;

import { makeEmailLowercaseAndTrim } from '@common/utils/makeEmailLowercaseAndTrim';
import { useRouter } from 'next/router';
import { signIn } from '../../../../lib/userActions';

const useLogin = () => {
  const router = useRouter();
  const { token } = router.query;

  const onLogin = async (username: string, password: string) => {
    const modifiedUsername = makeEmailLowercaseAndTrim(username);
    const result = await signIn(modifiedUsername, password);
    if (!result?.message) {
      if (router?.query?.redirect) {
        await router.replace(router.query.redirect as string);
      } else if (token) {
        await router.replace(`/join-organization?token=${String(token)}`);
      } else {
        await router.replace('/select-organization');
      }
    }
    return result;
  };

  return {
    operations: { onLogin },
  };
};

export default useLogin;

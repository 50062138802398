import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode
  className?: string
}

const FadeInOut = ({ children, className = '' }: Props) => (
  <motion.div
    exit={{ opacity: 0 }}
    initial='initial'
    animate='animate'
    className={className}
  >
    {children}
  </motion.div>
);

export default FadeInOut;

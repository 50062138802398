import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  spinner: {
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    position: 'relative',
    textIndent: '-9999em',
    '-webkit-transform': 'translateZ(0)',
    '-ms-transform': 'translateZ(0)',
    transform: 'translateZ(0)',
    '-webkit-animation': '$spinner 1.1s infinite linear',
    animation: '$spinner 1.1s infinite linear',
    borderTop: `12px solid ${theme.palette.primary.main}`,
    borderRight: `12px solid ${theme.palette.primary.main}`,
    borderBottom: `12px solid ${theme.palette.primary.main}`,
    borderLeft: '12px solid #ffffff',

    '&:after': {
      borderRadius: '50%',
      width: '80px',
      height: '80px',
    },
  },
  '@keyframes spinner': {
    from: {
      '-webkit-transform': 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
    to: {
      '-webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
}));

const PageLoader = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.spinner} />
    </div>
  );
};

export default PageLoader;

import { useCallback, useEffect, useState } from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';

type FormValues = {
  username: string,
  password: string,
};

interface ResponseProp {
  message?: string;
}

interface Props {
  actions: {
    onLogin: (username: string, password: string) => Promise<any>
  }
  getValues: UseFormGetValues<FieldValues>
}

const useLogin = ({ actions, getValues }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const onSubmit = useCallback(({ username, password }: FormValues) => {
    setLoading(true);
    setError('');
    actions.onLogin(username, password)
      .then((res: ResponseProp) => {
        if (res?.message) {
          setError(res?.message);
        }
        setLoading(false);
      })
      .catch((err: Error) => {
        setError(err.message);
        setLoading(false);
      });
  }, [actions]);

  const deleteServerError = () => {
    setError('');
  };

  const onPressEnter = useCallback((event) => {
    if (event.key === 'Enter') {
      const { password, username } = getValues();
      onSubmit({ password, username });
    }
  }, [getValues, onSubmit]);

  useEffect(() => {
    document.addEventListener('keydown', onPressEnter, false);

    return () => {
      document.removeEventListener('keydown', onPressEnter, false);
    };
  }, [onPressEnter]);

  return {
    operations: { onSubmit, deleteServerError },
    models: { error, loading },
  };
};

export default useLogin;

import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode
  className?: string
  delay?: number
}

const StaggerParent = ({
  children, delay = 0.1, className = '', ...rest
}: Props) => (
  <motion.div
    variants={{
      animate: {
        transition: {
          staggerChildren: delay,
        },
      },
    }}
    className={className}
    {...rest}
  >
    {children}
  </motion.div>
);

export default StaggerParent;
